'use client';

import { ProductCardCarousel } from '@wla/components/ui/product-card-carousel';
import { Spinner } from '@wla/components/ui/spinner';
import { Wrapper } from '@wla/components/ui/wrapper';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { ProductFullAllOfRelations, ProductTeaser } from '@jysk/api-types/drupalApi';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';

type ProductsInSameCollectionProps = {
  relations: ProductFullAllOfRelations;
};

export function ProductsInSameCollection({ relations }: ProductsInSameCollectionProps) {
  const t = useTranslations();
  const { fetchOnlineWithStoreStatusAtp } = useAtp();
  const relationSeriesProductIds = relations?.series?.productIds || [];
  const hasRelations = relationSeriesProductIds.length > 0;

  const [stockAtp, setStockAtp] = useState<OnlineAtpMap | null>();
  const [productsInSeries, setProductInSeries] = useState<ProductTeaser[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!hasRelations) return;

      setIsLoading(true);
      const [productResponse, stockAtp] = await Promise.all([
        fetch(`/checkout/api/get-products`, {
          method: 'POST',
          body: JSON.stringify({ productIds: relationSeriesProductIds }),
        }),
        fetchOnlineWithStoreStatusAtp(relationSeriesProductIds),
      ]);

      const { products: productsInSeries } = await productResponse.json();

      setProductInSeries(productsInSeries);
      setStockAtp(stockAtp);
      setIsLoading(false);
    }

    fetchData();
  }, [relations?.series?.productIds]);

  if (!hasRelations) return;

  //TODO: Consider moving the product fetching out to server and keep stock fetching on client and add isLoading to the productCardCarousel on the stockatp and pass it down to the productCard
  return (
    <Wrapper className="flex flex-col">
      <div className="mb-4 text-3xl font-semibold md:text-5xl lg:mb-8">{t('pdp.articles-in-same-collection')}</div>
      {isLoading && (
        <div className="flex w-full items-center justify-center">
          <Spinner size="xl" />
        </div>
      )}
      {!isLoading && (
        <ProductCardCarousel products={productsInSeries} refId="productInSameCollection" stockData={stockAtp} />
      )}
    </Wrapper>
  );
}
