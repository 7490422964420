'use client';
import NiceModal from '@ebay/nice-modal-react';
import { PaginatedReviews } from '@jysk/api-types/wssReviewsApi/model';
import { Rating } from '@wla/components/ui/rating/rating';
import { usePublicConfig } from '@wla/lib/configs';
import { DRAWER_ID } from '@wla/lib/modals';
import { getTotalRating } from './get-total-rating';

type ReviewsSummaryProps = {
  avgApprovedRating: PaginatedReviews['avg_approved_rating'];
  totalRatingsAmount: PaginatedReviews['approved'];
  reviews: PaginatedReviews;
  productId: string;
};

export function ReviewsSummary({ avgApprovedRating, totalRatingsAmount, productId, reviews }: ReviewsSummaryProps) {
  const totalRating = getTotalRating(avgApprovedRating);
  const {
    featureFlags: { enableProductCustomerReviews },
  } = usePublicConfig();

  if (!enableProductCustomerReviews) return;
  return (
    <button
      onClick={() => NiceModal.show(DRAWER_ID.REVIEW_DRAWER, { productId, reviews })}
      className="flex w-fit items-center"
    >
      <Rating size="lg" rating={totalRating} className="cursor-pointer" />
      <div className="ml-2 cursor-pointer items-center justify-center whitespace-nowrap pb-1 hover:underline">
        ({totalRatingsAmount})
      </div>
    </button>
  );
}
