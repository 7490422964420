'use client';

import NiceModal from '@ebay/nice-modal-react';
import { DRAWER_ID } from '@wla/lib/modals';
import { useEffect } from 'react';

type PromptB2BLoginProps = {
  openB2BLogin: boolean;
};

export function PromptB2BLogin({ openB2BLogin }: PromptB2BLoginProps) {
  useEffect(() => {
    if (openB2BLogin) {
      NiceModal.show(DRAWER_ID.LOGIN_NAVIGATION_DRAWER_ID, { defaultTab: 'B2B' });
    }
  }, [openB2BLogin]);

  return null;
}
