'use client';

import { ProductInformationButton } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-information-button';
import { ProductCard } from '@wla/components/ui/product-card/product-card';
import { Spinner } from '@wla/components/ui/spinner';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { ProductFullAllOfRelations, ProductTeaser } from '@jysk/api-types/drupalApi';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';

export type ProductConsistOfListProps = {
  relations?: ProductFullAllOfRelations;
};

export function ProductConsistOfList({ relations }: ProductConsistOfListProps) {
  const t = useTranslations();
  const { fetchOnlineWithStoreStatusAtp } = useAtp();
  const relationSetProductIds = relations?.set?.productIds || [];
  const hasRelations = relationSetProductIds.length > 0;

  const [stockAtp, setStockAtp] = useState<OnlineAtpMap | null>();
  const [products, setProducts] = useState<ProductTeaser[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!hasRelations) return;

      setIsLoading(true);
      const [productResponse, stockAtp] = await Promise.all([
        fetch(`/checkout/api/get-products`, {
          method: 'POST',
          body: JSON.stringify({ productIds: relationSetProductIds }),
        }),
        fetchOnlineWithStoreStatusAtp(relationSetProductIds),
      ]);

      const { products } = await productResponse.json();

      setProducts(products);
      setStockAtp(stockAtp);
      setIsLoading(false);
    }

    fetchData();
  }, [relations?.set?.productIds]);

  if (!hasRelations) return;

  return (
    <ProductInformationButton title={t('pdp.set-consist-of')}>
      {isLoading && (
        <div className="flex w-full items-center justify-center">
          <Spinner size="lg" />
        </div>
      )}
      {!isLoading && (
        <div className="grid grid-cols-2 gap-2.5 pt-5 lg:gap-3.5 lg:pt-8">
          {products?.map((product) => (
            <ProductCard key={product.id} product={product} stockStatus={stockAtp?.[product.id]} />
          ))}
        </div>
      )}
    </ProductInformationButton>
  );
}
