'use client';

import { LiveChatButton } from '@wla/app/(cms)/[...slug]/(faq-page)/live-chat-button';
import { SalesForceForm } from '@wla/app/(cms)/[...slug]/(faq-page)/sales-force-form';
import { Collapsible } from '@wla/components/ui/collapsible/collapsible';
import { Text } from '@wla/components/ui/text';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { FaqCategoryFullAllOfSections, StoreFull } from '@jysk/api-types/drupalApi';

type FaqItemsProps = {
  sections: FaqCategoryFullAllOfSections;
  storesList: StoreFull[];
};

export function FaqItems({ sections, storesList }: FaqItemsProps) {
  const t = useTranslations('customer-service');
  const searchParams = useSearchParams();
  const question = searchParams.get('question');
  const [openQuestion, setOpenQuestion] = useState<string | null>(null);

  useEffect(() => {
    setOpenQuestion(question || null);
    requestAnimationFrame(() => {
      if (!question) return;
      const element = document.querySelector(`[id*='${question}']`);
      element?.scrollIntoView({ behavior: 'smooth' });
    });
  }, [question]);

  return (
    <div className="mt-8 flex flex-col gap-8 lg:gap-16">
      {sections?.map((section) => (
        <div className="text-3xl font-semibold lg:text-5xl" key={section.title}>
          <div className="border-b pb-4">{section.title}</div>
          {section.items?.map(
            (item) =>
              item.description && (
                <div key={item.title} className="border-b" id={item.url.split('#')?.[1]}>
                  <Collapsible
                    className="my-5 text-left text-base font-normal lg:my-8 lg:text-lg"
                    title={item.title}
                    defaultOpen={item.url.includes(`question=${openQuestion}`)}
                  >
                    <div className="mb-8 text-base font-normal">
                      <Text value={item.description} />
                      <p className="pt-8">{t('was-your-question-answered')}</p>
                      <LiveChatButton
                        className="mr-2 mt-2"
                        onLineContent={<span>{t('chat-is-online')}</span>}
                        offLineContent={<span>{t('chat-is-offline')}</span>}
                      />
                      <SalesForceForm
                        email={item.email}
                        showOrderNumber={item.orderNumber}
                        storesList={storesList}
                        subject={item.title}
                        fileUpload={item.fileUpload}
                      />
                    </div>
                  </Collapsible>
                </div>
              ),
          )}
        </div>
      ))}
    </div>
  );
}
