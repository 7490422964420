'use client';
import { GetPage200EntityData } from '@jysk/api-types/drupalApi';
import { PageContext } from '@wla/app/(cms)/[...slug]/page-provider';
import { useContext, useEffect } from 'react';

type SetPageContextProps = {
  entityData: GetPage200EntityData;
};
export function SetPageContext({ entityData }: SetPageContextProps) {
  const { setPage } = useContext(PageContext);

  useEffect(() => {
    setPage(entityData);
  }, [entityData, setPage]);

  return null;
}
