'use client';

import NiceModal from '@ebay/nice-modal-react';
import { ProductInformationButton } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-information-button';
import { ReviewBlock } from '@wla/app/(cms)/[...slug]/(product-detail-page)/reviews/review-block';
import { Rating } from '@wla/components/ui/rating/rating';
import { usePublicConfig } from '@wla/lib/configs';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { PaginatedReviews } from '@jysk/api-types/wssReviewsApi/model';
import { getTotalRating } from './get-total-rating';

type ReviewsProps = {
  reviews: PaginatedReviews;
  productId: string;
};

export function Reviews({ reviews, productId }: ReviewsProps) {
  const t = useTranslations('pdp');
  const totalRating = getTotalRating(reviews?.avg_approved_rating);
  const {
    featureFlags: { enableProductCustomerReviews },
  } = usePublicConfig();

  if (!enableProductCustomerReviews) return;

  return (
    <ProductInformationButton
      title={t('review.reviews')}
      btnSection={
        <div className="flex pt-0.5 md:pt-2">
          <Rating size="lg" rating={totalRating} />
          <div className="ml-2 pt-0.5">
            <a href="#rating">({reviews.approved})</a>
          </div>
        </div>
      }
    >
      <ReviewBlock
        reviews={reviews}
        productId={productId}
        onOpenCreateReviewDrawer={() => NiceModal.hide(DRAWER_ID.PRODUCTS_INFORMATION_DRAWER)}
      />
    </ProductInformationButton>
  );
}
