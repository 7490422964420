'use client';

import { RECENTLY_VIEWED_UPDATE_EVENT } from '@wla/app/(cms)/recently-viewed';
import { useEffect } from 'react';
import { ProductImageFull } from '@jysk/api-types/drupalApi';

type SetRecentlyViewedProductProps = {
  id: string;
  title: string;
  url: string;
  image?: ProductImageFull;
};

export const SetRecentlyViewedProduct = ({ id, image, url, title }: SetRecentlyViewedProductProps) => {
  // We are doing this because setting cookies should be in a server action or as here with a server route
  useEffect(() => {
    async function setRecentlyViewed() {
      await fetch('/api/set-recently-viewed', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          imageUrl: image?.url ?? '',
          url,
          imageAlt: image?.alt,
          title,
        }),
      });
      window?.dispatchEvent(new Event(RECENTLY_VIEWED_UPDATE_EVENT));
    }

    setRecentlyViewed();
  }, [id]);

  return null;
};
