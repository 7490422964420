'use client';
import { MediaGroupName } from '@wla/app/(cms)/[...slug]/(product-detail-page)/helpers/product-images-mapping';
import { isVideo } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-gallery-drawer';
import {
  FullImageType,
  ProductImage,
} from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-image';
import {
  FullVideoType,
  ProductVideo,
} from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-video';
import { cn } from '@wla/lib/helpers/cn';
import { ForwardedRef, forwardRef } from 'react';

export type MappedMediaItem = FullImageType | FullVideoType;

type ProductMediaType = {
  media: MappedMediaItem;
  image360?: FullImageType;
  index: number;
  totalMediasQuantity: number;
  className?: string;
  onProductMediaClick: (index: number) => void;
};

export const ProductMedia = forwardRef(function ProductMedia(
  { media, image360, index, totalMediasQuantity, className, onProductMediaClick }: ProductMediaType,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={cn(
        'relative flex aspect-[3/4] h-full w-full cursor-pointer snap-start justify-center overflow-hidden bg-gray-100',
        index % 5 >= 2 && index % 5 < 5 ? 'md:col-span-2' : 'md:col-span-3',
        {
          'aspect-[3/4] sm:aspect-[4/3] md:col-span-6': totalMediasQuantity === 1,
          'px-[10%] py-[20%]':
            media.groupName !== MediaGroupName.Detail &&
            media.groupName !== MediaGroupName.Environment &&
            media.groupName !== MediaGroupName.Video,
        },
        className,
      )}
    >
      {isVideo(media) ? (
        <ProductVideo
          video={media}
          index={index}
          totalMediasQuantity={totalMediasQuantity}
          onProductVideoClick={onProductMediaClick}
        />
      ) : (
        <ProductImage
          image={media}
          image360={image360}
          index={index}
          totalMediasQuantity={totalMediasQuantity}
          onProductImageClick={onProductMediaClick}
        />
      )}
    </div>
  );
});
