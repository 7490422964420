'use client';

import NiceModal from '@ebay/nice-modal-react';
import {
  StatusIndicator,
  StatusIndicatorStatus,
} from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/status-indicator';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { JSXElementConstructor, ReactElement, ReactNode } from 'react';

export type ProductAtpStatusProps = {
  message: string | ReactElement<any, string | JSXElementConstructor<any>> | ReactNode;
  status: StatusIndicatorStatus;
  productId: string;
  title?: string;
  subMessage?: string | ReactElement<any, string | JSXElementConstructor<any>> | ReactNode;
  getNotified?: boolean;
  subTextColor?: string;
  isClickCollect?: boolean;
  showStatusAndText?: boolean;
  isLoading?: boolean;
};

export function ProductAtpStatus({
  title,
  message,
  subMessage,
  status,
  getNotified,
  subTextColor,
  productId,
  isClickCollect = true,
  showStatusAndText = true,
  isLoading,
}: ProductAtpStatusProps) {
  const t = useTranslations('pdp');

  return (
    <>
      <StatusIndicator
        title={title}
        status={status}
        isProductCard={!isClickCollect}
        showStatusAndText={showStatusAndText}
        isLoading={isLoading}
      >
        {isLoading && (
          <div className="flex w-full animate-skeleton items-start gap-1.5 md:gap-x-2.5">
            <div className="h-6 w-full rounded bg-gray-200" />
          </div>
        )}
        {!isLoading && (
          <div>
            <StatusIndicator.Text>{message}</StatusIndicator.Text>
            {subMessage && (
              <StatusIndicator.Text className={subTextColor ?? 'text-gray-500'}>{subMessage}</StatusIndicator.Text>
            )}
            {getNotified && (
              <button
                onClick={() => NiceModal.show(DRAWER_ID.BACK_IN_STOCK_DRAWER_ID, { articleId: productId })}
                className="text-brand-500 hover:underline"
              >
                <StatusIndicator.Text>{t('product-atp.get-notified-when-in-stock')}</StatusIndicator.Text>
              </button>
            )}
          </div>
        )}
      </StatusIndicator>
    </>
  );
}
