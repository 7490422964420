'use client';

import { Label, RadioGroup } from '@headlessui/react';
import { ProductAtpOption } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/product-atp-options/product-atp-option';
import { ProductAtpStatus } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/product-atp-status';
import { StatusIndicatorStatus } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-atp/status-indicator';
import { usePublicConfig } from '@wla/lib/configs';
import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { useProductAtp } from '@wla/lib/hooks/product-atp/use-product-atp';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { OrderType } from '@wla/types/orders';
import { useTranslations } from 'next-intl';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { ProductFull } from '@jysk/api-types/drupalApi';
import { OnlineAtpWithNextAtp, OnlineAtpWithStoreStatusMap, StoreStatusAtp } from '@jysk/api-types/webSapApiV2/model';

const DELIVERY_OPTIONS = [OrderType.OnlineSales, OrderType.ClickCollect];

type ProductAtpOptionsListProps = {
  stockAtp?: OnlineAtpWithStoreStatusMap | null;
  product: ProductFull;
  className?: string;
  selected?: OrderType;
  isLoading?: boolean;
  onChange?(option: OrderType, status: StatusIndicatorStatus): void;
  onInit?(status: StatusIndicatorStatus): void;
};

export function ProductAtpOptionsList({
  onChange,
  selected,
  stockAtp,
  product,
  isLoading,
  onInit,
  children,
  className,
}: PropsWithChildren<ProductAtpOptionsListProps>) {
  const {
    featureFlags: { enableOnlineSales, enableClickCollect, showStoreStock, showOnlineStock },
    defaultLocale,
  } = usePublicConfig();

  const { id: productId, isOnlineSales } = product;

  const t = useTranslations();
  const { favoriteStore } = useFavoriteStore();
  const { fetchOnlineWithStoreStatusAtp } = useAtp();
  const { getOnlineStockStatus, getStoreStockStatus } = useProductAtp();
  const [storeAtp, setStoreAtp] = useState<StoreStatusAtp | undefined>(stockAtp?.store);
  const [onlineAtp, setOnlineAtp] = useState<OnlineAtpWithNextAtp | undefined>(stockAtp?.onlineAtp);

  useEffect(() => {
    async function fetchOnlineWithStoreStatusData() {
      const data = await fetchOnlineWithStoreStatusAtp([productId]);
      const storeAtp = data?.[productId]?.store;
      const onlineAtp = data?.[productId]?.onlineAtp;

      setStoreAtp(storeAtp);
      setOnlineAtp(onlineAtp);
    }

    fetchOnlineWithStoreStatusData();
  }, [productId, favoriteStore?.id]);

  const storeStatus = getStoreStockStatus({
    product,
    storesWithAtp: storeAtp?.totalNumberStoresWithATP ?? 0,
    onlineAtp: onlineAtp?.dcAtp?.quantity ?? 0,
    selectedStoreUnit: storeAtp?.inStockInSpecifedStore ? 1 : 0,
  });

  const onlineStatus = getOnlineStockStatus({
    productId,
    onlineQuantity: onlineAtp?.dcAtp?.quantity ?? 0,
    nextAtpDate: (() => {
      const nextDate = new Date(onlineAtp?.nextAtp?.[0]?.availabilityDate || '');
      return nextDate.getFullYear() === 9999
        ? ''
        : nextDate.toLocaleString(defaultLocale, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          });
    })(),
    isOnlineSales,
  });

  useEffect(() => {
    if (selected === OrderType.OnlineSales && !showOnlineStock) {
      onInit?.(StatusIndicatorStatus.Error);
    } else {
      onInit?.(selected === OrderType.ClickCollect ? storeStatus.status : onlineStatus.status);
    }
  }, []);

  const handleOnChange = useCallback(
    (option: OrderType) => {
      if (!onChange) return;

      onChange(option, option === OrderType.ClickCollect ? storeStatus.status : onlineStatus.status);
    },
    [
      storeStatus.status,
      onChange,
      onlineStatus.status,
      productId,
      isOnlineSales,
      onlineAtp?.dcAtp?.quantity,
      onlineAtp?.nextAtp,
      defaultLocale,
    ],
  );

  useEffect(() => {
    if (!selected) return;

    handleOnChange(selected);
  }, [favoriteStore?.id, storeStatus.status, onlineStatus.status]);

  return (
    <div className={className}>
      <RadioGroup value={selected} onChange={handleOnChange} className="flex w-full flex-col gap-3">
        <Label className="line text-base font-bold">{t('pdp.product-atp.delivery-or-pickup')}</Label>
        {DELIVERY_OPTIONS.map((option) => {
          if (option === OrderType.OnlineSales && !enableOnlineSales) return null;
          if (option === OrderType.ClickCollect && !enableClickCollect) return null;

          const stock = option === OrderType.OnlineSales ? onlineStatus : storeStatus;

          if (!stock) return null;

          let showStatusAndText = true;
          if (OrderType.OnlineSales === option && !showOnlineStock) {
            showStatusAndText = false;
          } else if (OrderType.ClickCollect === option && !showStoreStock) {
            showStatusAndText = false;
          }

          return (
            <ProductAtpOption
              key={option}
              id={option}
              message={stock.text}
              title={option === OrderType.OnlineSales ? t('common.delivery') : t('common.click-and-collect')}
              subMessage={stock.subText}
              status={stock.status}
              getNotified={stock.getNotified}
              productId={productId}
              showStatusAndText={showStatusAndText}
              isLoading={isLoading}
            />
          );
        })}
      </RadioGroup>
      {children}
      {!enableClickCollect && showStoreStock && (
        <div className="justify-self-end text-sm">
          <ProductAtpStatus
            message={storeStatus.text}
            status={storeStatus.status}
            getNotified={storeStatus.getNotified}
            productId={productId}
            isClickCollect={false}
          />
        </div>
      )}
    </div>
  );
}
