import { RoleType } from './product-quantity-input';

type ProductQuantityButtonProps = {
  role: RoleType;
  action: (role: RoleType, v: number) => void;
  disabled?: boolean;
  size?: 'sm' | 'lg';
};

export function ProductQuantityButton({ role, action, disabled = false, size = 'lg' }: ProductQuantityButtonProps) {
  return (
    <button
      disabled={disabled}
      onClick={() => action(role, 0)}
      type="button"
      aria-label={role === RoleType.INCREMENT ? 'Quantity increment' : 'Quantity decrement'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="square"
      >
        <line x1="5" y1="12" x2="19" y2="12" />
        {role === RoleType.INCREMENT && <line x1="12" y1="5" x2="12" y2="19" />}
      </svg>
    </button>
  );
}
