import { z } from 'zod';

export const salesForceEmailSchema = z.object({
  firstName: z
    .string()
    .min(1, 'forms.errors.required.first-name-is-required')
    .max(300, 'forms.errors.required.first-name-is-required'),
  lastName: z
    .string()
    .min(1, 'forms.errors.required.last-name-is-required')
    .max(300, 'forms.errors.required.last-name-is-required'),
  email: z.string().email('forms.errors.not-valid.email-not-valid'),
  phoneNumber: z
    .string()
    .regex(new RegExp('^(|\\d{8,20})$'), 'forms.errors.not-valid.phone-number-not-valid')
    .optional(),
  orderNumber: z
    .string()
    .regex(new RegExp('^\\d*$'), 'forms.erros.not-valid.order-number-must-be-numbers')
    .optional()
    .nullable(),
  store: z
    .string()
    .regex(new RegExp('^[0-9]{4}$|^[A-Z][0-9]{3}$'), 'forms.errors.not-valid.store-number-not-valid')
    .optional()
    .nullable(),
  message: z
    .string()
    .min(1, 'forms.errors.required.message-is-required')
    .max(1000, 'forms.errors.required.message-is-required'),
});

export type SalesForceEmailData = z.infer<typeof salesForceEmailSchema>;
