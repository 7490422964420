'use client';

import NiceModal from '@ebay/nice-modal-react';
import { Icon } from '@wla/components/ui/icon';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { DRAWER_ID } from '@wla/lib/modals';
import { ProductStatusCodes } from '@wla/types/product';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { ProductFull } from '@jysk/api-types/drupalApi';

type ProductAtpButtonProps = {
  product: ProductFull;
};

export function StockInStoreButton({ product }: ProductAtpButtonProps) {
  const t = useTranslations('pdp');
  const { fetchStoresAtp } = useAtp();
  const [storeAtp, setStoreAtp] = useState<{
    totalNumberStoresWithAtp: number;
    totalNumberStores: number;
    selectedStoreUnit: any;
    atLeastOneOnDisplay: boolean;
  } | null>(null);

  useEffect(() => {
    async function fetchStoreAtp() {
      const data = await fetchStoresAtp(product.id);
      setStoreAtp(data);
    }
    fetchStoreAtp();
  }, [product.id]);

  const showStoreStockIcon =
    product.showOnDisplayLabel &&
    (product.statusCode === ProductStatusCodes.PROCURED_ARTICLES || storeAtp?.atLeastOneOnDisplay);

  if (!showStoreStockIcon) return;

  //TODO: Consider skeleton while loading the store data
  return (
    <div className="my-1 w-full border-y border-gray-300 py-5">
      <button
        className="flex items-center"
        onClick={() =>
          NiceModal.show(DRAWER_ID.FAVORITE_STORE_DRAWER, {
            product,
            showInStoreFilter: true,
          })
        }
      >
        <Icon src="w3-click-and-collect" size="20" />
        <p className="pl-2.5 hover:underline">{t('see-stock-in-store')}</p>
      </button>
    </div>
  );
}
