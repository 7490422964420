import { Description, Radio } from '@headlessui/react';
import { Alert } from '@wla/components/ui/alert';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { OrderType } from '@wla/types/orders';
import { useTranslations } from 'next-intl';
import { ErrorBoundary } from 'react-error-boundary';
import { ProductAtpStatus, ProductAtpStatusProps } from '../product-atp-status';

type ProductAtpOptionProps = ProductAtpStatusProps & {
  id: OrderType;
  productId: string;
  showStatusAndText?: boolean;
  isLoading?: boolean;
};

export function ProductAtpOption({
  id,
  message,
  status,
  title,
  getNotified,
  subMessage,
  subTextColor,
  productId,
  showStatusAndText,
  isLoading,
}: ProductAtpOptionProps) {
  const t = useTranslations('pdp');

  return (
    <Radio
      key={id}
      value={id}
      className={({ checked }) =>
        cn('relative flex cursor-pointer rounded bg-white px-5 py-4 ring-1 ring-gray-400 focus:outline-none', {
          'ring-2 ring-brand-500': checked,
        })
      }
    >
      {({ checked }) => (
        <>
          <div className="flex w-full flex-col">
            <Description as="span" className="inline">
              <ErrorBoundary fallback={<Alert>{t('errors.add-to-basket-not-available')}</Alert>}>
                <ProductAtpStatus
                  message={message}
                  status={status}
                  title={title}
                  getNotified={getNotified}
                  subMessage={subMessage}
                  subTextColor={subTextColor}
                  productId={productId}
                  showStatusAndText={showStatusAndText}
                  isLoading={isLoading}
                />
              </ErrorBoundary>
              {id === 'ONLINE_SALES' && !showStatusAndText && (
                <Alert type="info" className="mt-1">
                  {t('product-atp.online-messages.delivery-is-not-possible')}
                </Alert>
              )}
            </Description>
          </div>
          {checked && (
            <div className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2 transform">
              <Icon src="w3-checkmark" size="20" className="inline w-5 rounded-full bg-brand-500 text-white" />
            </div>
          )}
        </>
      )}
    </Radio>
  );
}
