'use client';
import NiceModal from '@ebay/nice-modal-react';
import {
  ProductImageFull,
  ProductImageFullAllOfTypeGroup,
  ProductLabelsItem,
  ProductMediaVideos,
} from '@jysk/api-types/drupalApi';
import {
  MediaGroupName,
  applyExtraProperties,
} from '@wla/app/(cms)/[...slug]/(product-detail-page)/helpers/product-images-mapping';
import { isVideo } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-gallery-drawer';
import { FullImageType } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-image';
import {
  MappedMediaItem,
  ProductMedia,
} from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-medias/product-media';
import { Alert } from '@wla/components/ui/alert';
import { Button } from '@wla/components/ui/button/button';
import { ProductStickers } from '@wla/components/ui/product/product-stickers';
import { cn } from '@wla/lib/helpers/cn';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { useEffect, useRef, useState } from 'react';

type ProductMediasProps = {
  images?: ProductImageFull[];
  videos?: ProductMediaVideos;
  labels?: ProductLabelsItem[] | null;
  discount?: number;
};

/**
 * @description In this component, images and videos are sorted in a specific order. Sorting logic:
 * 1. First item should always be the first item of the original image list.
 * 2. Second item should be of groupName "cutoff", if first was "environment". And other way round
 * 3. Third should be a video. Fourth of groupName "detail", then "function", then "fallback".
 * 4. Then repeat the same order again and again until all received images and videos are in its new order. If there are no items (left) of a specific groupName, skip and take next one.
 */

export function ProductMedias({ images, videos, labels, discount }: ProductMediasProps) {
  const t = useTranslations();
  const imageRefs = useRef<(Element | null)[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [ignoreObserver, setIgnoreObserver] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(updateActivePagination, options);
    imageRefs.current.map((image) => image && observer.current?.observe(image));

    return () => observer.current?.disconnect();
  }, [images, ignoreObserver]);

  // 1. combine images and videos into one array
  const mediaItems = [...(images ?? []), ...(videos ?? [])];

  // 2. Apply extra properties to mediaItems
  const mappedMediaItems = mediaItems.map((item) => applyExtraProperties(item));

  // 3. Change groupSortOrder if the first item of the list has groupName="environment"
  const updatedMappedMediaItems = mappedMediaItems.map((item) => {
    if (mappedMediaItems[0].groupName !== MediaGroupName.Environment) return item;
    if (item.groupName === MediaGroupName.CutOut) return { ...item, groupSortOrder: 2 };
    if (item.groupName === MediaGroupName.Environment) return { ...item, groupSortOrder: 1 };
    return item;
  });

  // 4. Group mediaItems by their groupName, like [ [all items with groupName "cutOut"], [all items with groupName "environment"], ...]
  const groupedMediaItems = Object.values(
    updatedMappedMediaItems.reduce(
      (acc, cur) => ({ ...acc, [cur.groupName]: (acc[cur.groupName] || []).concat(cur) }),
      {} as Record<string, MappedMediaItem[]>,
    ),
  );

  // 5. first sort each item of an array by childSortOrder (asc.) and then sort the arrays by groupSortOrder (asc.)
  const sortedMediaItemsArrays = groupedMediaItems
    .map((mediaItemArray) => mediaItemArray.sort((a, b) => a.childSortOrder - b.childSortOrder))
    .sort((a, b) => a[0].groupSortOrder - b[0].groupSortOrder);

  const lengthOfLongestArray = sortedMediaItemsArrays.reduce((prev, cur) => (cur.length > prev ? cur.length : prev), 0);

  // 6. sort the sortedMediaItemsArray like the expected logic mentioned above
  const sortedMediaItems = Array.from({ length: lengthOfLongestArray }, (_, i) =>
    sortedMediaItemsArrays.map((mediaItemArray, arrIndex) => {
      // very first item should always be the first item of the original image list
      if (i === 0 && arrIndex === 0) return updatedMappedMediaItems[0];
      // else: take the first item of each group, then the second, the third and so on (alternately array)
      return mediaItemArray[i];
    }),
  )
    .flat()
    .filter(Boolean);

  const image360 = sortedMediaItems.find(
    (media) => (media as FullImageType).typeGroup === ProductImageFullAllOfTypeGroup.NUMBER_360,
  ) as FullImageType | undefined;

  const sortedMediaItemsWithout360 = sortedMediaItems?.filter(
    (media) => (media as FullImageType).typeGroup !== ProductImageFullAllOfTypeGroup.NUMBER_360,
  );

  if (sortedMediaItems.length === 0) {
    return (
      <Alert type="error" className="h-fit">
        {t('pdp.errors.missing-image')}
      </Alert>
    );
  }

  function handleGoToImage(index: number) {
    setIgnoreObserver(true);
    imageRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
    setActiveIndex(index);
    setTimeout(() => setIgnoreObserver(false), 1000);
  }

  function updateActivePagination(entries: IntersectionObserverEntry[]) {
    if (ignoreObserver) return;
    entries.map((entry) => entry.isIntersecting && setActiveIndex(imageRefs.current.indexOf(entry.target)));
  }

  return (
    <>
      <div className="scrollbar-hide grid snap-x snap-mandatory auto-cols-[minmax(100%,_1fr)] grid-flow-col overflow-x-auto md:grid-flow-row md:grid-cols-6 md:gap-8">
        <ProductStickers
          stickers={labels}
          size="large"
          discount={discount}
          isPdp
          className="absolute left-0 top-0 z-10 my-6 flex-col items-start font-semibold lg:my-7"
        />
        {sortedMediaItemsWithout360.map((media, index) => (
          <ProductMedia
            key={isVideo(media) ? media.externalId : `${media.alt}-${index}`}
            ref={(el: HTMLDivElement | null) => {
              imageRefs.current[index] = el;
            }}
            className={cn(index >= 7 && 'lg:hidden')}
            media={media}
            image360={image360}
            index={index}
            totalMediasQuantity={sortedMediaItemsWithout360.length}
            onProductMediaClick={(clickedIndex) =>
              NiceModal.show(DRAWER_ID.PRODUCT_GALLERY_DRAWER, {
                medias: sortedMediaItemsWithout360,
                initialSlide: clickedIndex,
              })
            }
          />
        ))}
      </div>
      <div className="absolute bottom-6 right-6 grid h-2 w-full grid-flow-col items-center justify-end gap-2 md:hidden">
        {sortedMediaItemsWithout360?.map((_, index) => (
          <button
            className={cn(
              'h-2 w-2 rounded-full bg-gray-300 transition-all duration-300 hover:h-2 hover:w-2',
              // The father away from the activeIndex the smaller the dot
              activeIndex === index && 'bg-gray-600',
              Math.abs(activeIndex - index) > 1 && 'h-1.5 w-1.5',
              Math.abs(activeIndex - index) > 2 && 'hidden',
            )}
            onClick={() => handleGoToImage(index)}
            key={index}
          />
        ))}
      </div>
      {sortedMediaItemsWithout360.length > 7 && (
        <div className="mb-8 mt-16 hidden flex-col items-center justify-center lg:flex">
          <Button
            variant="secondary"
            onClick={() => NiceModal.show(DRAWER_ID.PRODUCT_GALLERY_DRAWER, { medias: sortedMediaItems })}
          >
            {t('common.show-all')}
          </Button>
        </div>
      )}
    </>
  );
}
