'use client';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

type CountdownTimerProps = {
  start?: string;
  end: string;
  showCampaignText?: boolean;
};

export function CountdownTimer({ end, showCampaignText = false }: CountdownTimerProps) {
  const t = useTranslations('pdp');
  const [timeLeft, setTimeLeft] = useState<ReturnType<typeof calculateTimeLeft> | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  // TODO: In JRO and JDE it is a legal requirement to show both the start date and the end date
  // TODO: In JDE it is a legal requirement to also show this on PLP
  // TODO: In JDE it is a legal requirement to also show a text even when end_date = 9999-12-31
  // These are out of scope for the MVP

  if (end === '9999-12-31') return null;

  function calculateTimeLeft(): { days: number; hours: number; minutes: number; seconds: number } {
    if (!end) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const endDate = new Date(end);
    const now = new Date();

    const difference = endDate.getTime() - now.getTime();
    if (difference <= 0) {
      // If the end date has passed, return all zeros
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  if (!timeLeft) {
    return null;
  }

  const days = timeLeft.days;
  const hours = timeLeft.hours;
  const minutes = timeLeft.minutes;
  const seconds = timeLeft.seconds;

  const noMoreTime = days === 0 && hours === 0 && minutes === 0 && seconds === 0;
  const overOneDayLeft = timeLeft.days >= 1 && timeLeft.seconds >= 0;

  if (showCampaignText && !noMoreTime && end !== '9999-12-31' && overOneDayLeft) {
    return (
      <div className="py-1 text-sm">
        {t('price-valid-until', {
          endDate: end,
        })}
      </div>
    );
  }

  return (
    <>
      {!noMoreTime && !overOneDayLeft && showCampaignText === false ? (
        <p className="flex w-fit items-center bg-gray-100 px-1.5 py-1 text-sm">
          {t('campaign-counter', {
            days,
            hours,
            minutes,
            seconds,
          })}
        </p>
      ) : null}
    </>
  );
}
