import { ProductMediaVideosOneOfItem } from '@jysk/api-types/drupalApi';
import { AdditionalMediaProps } from '@wla/app/(cms)/[...slug]/(product-detail-page)/helpers/product-images-mapping';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import Image from 'next/image';

export type FullVideoType = ProductMediaVideosOneOfItem & AdditionalMediaProps;

type ProductVideoType = {
  video: FullVideoType;
  index: number;
  totalMediasQuantity: number;
  onProductVideoClick: (index: number) => void;
};

export function ProductVideo({ video, index, totalMediasQuantity, onProductVideoClick }: ProductVideoType) {
  return (
    <>
      <Image
        priority
        src={video.thumbnailUrl}
        alt={video.title}
        width={video.dimensions?.width ?? 600}
        height={video.dimensions?.height ?? 800}
        className={cn('h-full object-cover', { 'w-full': totalMediasQuantity === 1 })}
        onClick={() => onProductVideoClick(index)}
      />
      <button
        onClick={() => onProductVideoClick(index)}
        className="absolute bottom-1/2 right-1/2 flex h-10 w-10 translate-x-1/2 translate-y-1/2 items-center justify-center rounded-full bg-white p-2.5"
      >
        <Icon src="w3-play" />
      </button>
    </>
  );
}
