import { OrderType } from '@wla/types/orders';
import { useCallback, useEffect, useState } from 'react';
import { ProductQuantityButton } from './product-quantity-button';
import { ProductQuantityText } from './product-quantity-text';

type ProductQuantityInputProps = {
  step: number;
  quantity: number;
  onChange: (v: number) => void;
  min: number;
  stepper?: boolean;
  size?: 'sm' | 'lg';
  textClassName?: string;
  loading?: boolean;
  setRole?: (role: string) => void;
  deliveryType?: OrderType | undefined;
  onlineMinimumQuantity?: number;
};

export enum RoleType {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
  DIRECT = 'DIRECT',
}

export function ProductQuantityInput({
  step,
  quantity,
  onChange,
  min,
  stepper = false,
  size = 'lg',
  textClassName,
  loading = false,
  setRole,
  deliveryType,
  onlineMinimumQuantity,
}: ProductQuantityInputProps) {
  const [value, setValue] = useState<number>(quantity);

  // obey changes to quantity from outside
  useEffect(() => {
    if (!loading) {
      setValue(quantity);
    }
  }, [quantity, loading]);

  const handleAction = useCallback(
    async (role: RoleType, v: number) => {
      let newValue = v;
      switch (role) {
        case RoleType.DECREMENT: {
          if (setRole) {
            setRole(RoleType.DECREMENT);
          }
          const previousOnlineMinimumQuantity =
            onlineMinimumQuantity &&
            Math.max(min, Math.floor((value - 1) / onlineMinimumQuantity) * onlineMinimumQuantity);

          newValue =
            deliveryType === OrderType.OnlineSales
              ? Math.max(
                  onlineMinimumQuantity ?? 1,
                  previousOnlineMinimumQuantity || min,
                  previousOnlineMinimumQuantity || value,
                )
              : Math.max(min, value - step);

          break;
        }
        case RoleType.INCREMENT: {
          if (setRole) {
            setRole(RoleType.INCREMENT);
          }

          const nextOnlineMinimumQuantity = onlineMinimumQuantity
            ? Math.ceil((value + 1) / onlineMinimumQuantity) * onlineMinimumQuantity
            : 1;

          newValue = deliveryType === OrderType.OnlineSales ? (newValue = nextOnlineMinimumQuantity) : value + step;
          break;
        }
        case RoleType.DIRECT: {
          newValue = Math.max(min, v);
          if (setRole) {
            setRole(RoleType.DIRECT);
          }
          break;
        }
      }

      if (newValue !== value) {
        setValue(newValue);
        onChange(newValue);
      }
    },
    [min, step, value, onChange, setRole],
  );

  return (
    <div className="flex rounded border border-gray-400 px-4">
      {stepper && (
        <ProductQuantityButton role={RoleType.DECREMENT} action={handleAction} disabled={loading} size={size} />
      )}
      <ProductQuantityText
        role={RoleType.DIRECT}
        value={value}
        action={handleAction}
        step={step}
        min={min}
        className={textClassName}
        disabled={loading}
        size={size}
      />
      {stepper && (
        <ProductQuantityButton role={RoleType.INCREMENT} action={handleAction} disabled={loading} size={size} />
      )}
    </div>
  );
}
