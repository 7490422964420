'use client';

import NiceModal from '@ebay/nice-modal-react';
import { StoreType } from '@wla/app/(cms)/(favorite-store)/favorite-store-list';
import { PowerStepDrawerProps } from '@wla/app/(cms)/[...slug]/(product-detail-page)/power-step-drawer/power-step-drawer';
import { findProduct } from '@wla/app/checkout/order-states';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { AddedRecommendedProduct } from '@wla/components/ui/product-card/product-card';
import { ProductQuantityInput } from '@wla/components/ui/product-quantity-input/product-quantity-input';
import { errorToast } from '@wla/components/ui/toasters';
import { usePublicConfig } from '@wla/lib/configs';
import { getProductAsOrderLine } from '@wla/lib/helpers/(analytics)/get-product-as-orderline';
import { countOrderLinesInOrder } from '@wla/lib/helpers/shopping-session';
import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { useAtp } from '@wla/lib/hooks/use-atp';
import { DRAWER_ID } from '@wla/lib/modals';
import { FetchShoppingSessionResponse } from '@wla/lib/shopping-session/shopping-session-provider';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { trackAddToBasket, trackCheckStock } from '@wla/lib/tracking/google-analytics-tracking';
import { ErrorMessageCodeType, OrderType } from '@wla/types/orders';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ProductFull } from '@jysk/api-types/drupalApi';
import { CarrierOption } from '@jysk/api-types/orderManagementApi/model';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';
import { ProductAtpOptionsList } from './product-atp/product-atp-options/product-atp-options-list';
import { StatusIndicatorStatus } from './product-atp/status-indicator';

export type OnlineAtp = {
  nextAtpDate: string;
  qty: number;
  nextAtpQty: number;
  nextAtpDC: string;
};

type StoreInformation = {
  storeId?: string;
  zipCode?: string;
  city?: string;
};

type HandleAddToBasketType = {
  viaPowerStep: boolean;
  response: FetchShoppingSessionResponse;
  orderType: OrderType;
  carriers?: CarrierOption[];
  counter?: number;
};

type AddToBasketProps = {
  product: ProductFull;
};

export function AddToBasket({ product }: AddToBasketProps) {
  const t = useTranslations();

  const {
    featureFlags: { enableClickCollect, showOnlineStock },
    currency,
  } = usePublicConfig();
  const { fetchOnlineWithStoreStatusAtp } = useAtp();
  const [stockAtp, setStockAtp] = useState<OnlineAtpMap | null>(null);
  const [isStockLoading, setIsStockLoading] = useState(true);

  const { onlineMinimumQuantity, relations, id: productId, onlineMinimumPrice } = product;
  const { addToBasket, shoppingSession } = useShoppingSession();
  const { favoriteStore } = useFavoriteStore();

  const [orderType, setOrderType] = useState<OrderType>(OrderType.OnlineSales);

  const [loadingButton, setLoadingButton] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  // selectedQuantity is handled separately instead of added to the productRef, because it's only needed and changeable for the pdp product.
  // We need to use ref's instead of state's here. Otherwise we still have the "old" state value when using inside the functions.
  // For the recommended product we always add the minimumQuantity or 1.
  const selectedQuantityRef = useRef<number>(orderType == OrderType.OnlineSales ? onlineMinimumQuantity || 1 : 1);
  const storeInformationRef = useRef<StoreInformation>();
  // productRef could be the pdp product or a recommended product from the power-step-drawer
  const productRef = useRef<Omit<ProductFull, 'onlineMinimumQuantity'> & { onlineMinimumQuantity: number }>({
    ...product,
    onlineMinimumQuantity: onlineMinimumQuantity || 1,
  });
  const prevAddedProductRef = useRef<PowerStepDrawerProps>();

  function getProductBasketQuantity() {
    const order = shoppingSession?.active?.orders?.find((order) => order.orderType === orderType);
    return (
      order?.deliveryGroups?.[0].orderLines?.find((orderLine) => orderLine.article === productRef.current.id)
        ?.quantity || 0
    );
  }

  function defaultDeliveryType(stockAtp: OnlineAtpMap | null) {
    const hasClickAndCollectProduct = Boolean(
      findProduct({
        orders: shoppingSession?.active?.orders ?? [],
        type: OrderType.ClickCollect,
        productId: product.id,
      })?.length,
    );

    const hasOnlineProduct = Boolean(
      findProduct({
        orders: shoppingSession?.active?.orders ?? [],
        type: OrderType.OnlineSales,
        productId: product.id,
      })?.length,
    );

    const isMixed = hasClickAndCollectProduct && hasOnlineProduct;
    const onlyOnlineProduct = hasOnlineProduct && !hasClickAndCollectProduct;
    const onlyClickAndCollectProduct = hasClickAndCollectProduct && !hasOnlineProduct;
    const isCcInStoreStock = stockAtp?.[product.id]?.store?.inStockInSpecifedStore;
    const isOnlineInStoreStock = (stockAtp?.[product.id]?.onlineAtp?.dcAtp?.quantity ?? 0) > 0;

    if (isMixed || onlyOnlineProduct || (!isCcInStoreStock && favoriteStore?.id)) return OrderType.OnlineSales;

    if (onlyClickAndCollectProduct) return OrderType.ClickCollect;

    if (favoriteStore?.id || !product.isOnlineSales || !isOnlineInStoreStock) return OrderType.ClickCollect;

    return OrderType.OnlineSales;
  }

  useEffect(() => {
    async function fetchAtp() {
      setIsStockLoading(true);
      const atpData = await fetchOnlineWithStoreStatusAtp([product.id]);
      setStockAtp(atpData);

      const orderType = defaultDeliveryType(atpData);
      setOrderType(enableClickCollect ? orderType : OrderType.OnlineSales); //If feature flag is disabled, always set to online sales
      setIsStockLoading(false);
    }
    fetchAtp();
  }, [product.id, favoriteStore?.id]);

  function getAddToBasketQuantity(viaPowerStep: boolean) {
    return viaPowerStep ? productRef.current.onlineMinimumQuantity : selectedQuantityRef.current;
  }

  async function checkMinimumQuantity(viaPowerStep: boolean, productTotalQuantityInBasket: number) {
    const checkMinimumQuantity = !viaPowerStep && selectedQuantityRef.current % onlineMinimumQuantity! !== 0;
    if (!checkMinimumQuantity) return { takeMinQuantity: true, quantity: undefined };
    const stockQuantity = stockAtp?.[productRef.current.id]?.onlineAtp?.dcAtp?.quantity;
    return await NiceModal.show<{ takeMinQuantity: boolean; quantity: number }>(DRAWER_ID.ONLINE_MIN_QTY_MODAL, {
      onlineMinimumQuantity: productRef.current.onlineMinimumQuantity,
      productName: productRef.current.title,
      selectedQuantity: selectedQuantityRef.current,
      leftQuantity: stockQuantity ? stockQuantity - productTotalQuantityInBasket : 0,
      onClick: (value: number) => (selectedQuantityRef.current = value),
    });
  }

  async function handleOnlineSale(viaPowerStep: boolean) {
    const productBasketQuantity = getProductBasketQuantity();

    // Check if quantity is a multiple of the online minimum quantity. This check is only needed if the product is added directly from the pdp.
    const { takeMinQuantity, quantity } = await checkMinimumQuantity(viaPowerStep, productBasketQuantity);

    if (!takeMinQuantity) {
      setLoadingButton(false);
      return;
    }

    const basketResponse = await addToBasket({
      productId: productRef.current.id,
      orderType,
      quantity: quantity ?? getAddToBasketQuantity(viaPowerStep),
    });

    // If the selected quantity is higher than the stock availability, user has the possibility to add the highest possible quantity.
    if (!basketResponse.success && basketResponse.json?.errorType === ErrorMessageCodeType.INSUFFICIENT_ATP) {
      const takeLeftStock = await NiceModal.show(DRAWER_ID.MAX_QTY_MODAL, {
        atp: basketResponse.json.parameters?.atp,
        leftQuantity: Number(basketResponse.json.parameters?.atp) - productBasketQuantity,
        showButton: Number(basketResponse.json.parameters?.atp) - productBasketQuantity !== 0,
        onClick: async (value: number) => (selectedQuantityRef.current = value),
      });

      if (takeLeftStock) {
        const basketRes = await addToBasket({
          productId: productRef.current.id,
          orderType,
          quantity: getAddToBasketQuantity(viaPowerStep),
        });

        const carriersRes = await fetch('/api/get-carrier-with-usecase', { cache: 'no-store' });
        const { carriers } = await carriersRes.json();
        return { response: basketRes, carriers };
      }

      if (!takeLeftStock) {
        setLoadingButton(false);
        return;
      }
    }

    const carriersResponse = await fetch('/api/get-carrier-with-usecase', { cache: 'no-store' });
    const { carriers } = await carriersResponse.json();

    return { response: basketResponse, carriers };
  }

  async function getStoreInformation() {
    if (favoriteStore) {
      storeInformationRef.current = {
        storeId: favoriteStore.id,
        zipCode: favoriteStore.zipCode,
        city: favoriteStore.city,
      };
      return { storeId: favoriteStore.id };
    }

    // Otherwise, user is prompted to select a store
    const store = await NiceModal.show<StoreType>(DRAWER_ID.FAVORITE_STORE_DRAWER, { product });

    storeInformationRef.current = { storeId: store.id, zipCode: store.zipCode, city: store.city };
    return { storeId: store.id };
  }

  async function handleClickAndCollect(viaPowerStep: boolean) {
    if (!storeInformationRef.current || !storeInformationRef.current.storeId) {
      const { storeId } = await getStoreInformation();
      !storeId && setLoadingButton(false);
    }

    const productBasketQuantity = getProductBasketQuantity();

    const response = await addToBasket({
      productId: productRef.current.id,
      orderType,
      quantity: getAddToBasketQuantity(viaPowerStep),
      storeId: orderType === OrderType.ClickCollect ? storeInformationRef.current?.storeId : undefined,
      zipCode: orderType === OrderType.ClickCollect ? storeInformationRef.current?.zipCode : undefined,
    });

    // If the selected quantity is higher than the stock availability, user has the possibility to add the highest possible quantity.
    if (!response.success && response.json?.errorType === ErrorMessageCodeType.INSUFFICIENT_ATP) {
      const takeLeftStock = await NiceModal.show<boolean>(DRAWER_ID.MAX_QTY_MODAL, {
        atp: response.json.parameters?.atp,
        leftQuantity: Number(response.json.parameters?.atp) - productBasketQuantity,
        showButton: Number(response.json.parameters?.atp) - productBasketQuantity !== 0,
        onClick: async (value: number) => (selectedQuantityRef.current = value),
      });

      if (takeLeftStock) {
        const basketResponse = await addToBasket({
          productId: productRef.current.id,
          orderType,
          quantity: getAddToBasketQuantity(viaPowerStep),
          storeId: orderType === OrderType.ClickCollect ? storeInformationRef.current?.storeId : undefined,
          zipCode: orderType === OrderType.ClickCollect ? storeInformationRef.current?.zipCode : undefined,
        });

        return { response: basketResponse };
      }

      if (!takeLeftStock) {
        setLoadingButton(false);
        return;
      }
    }

    return { response };
  }

  // This function is executed when the user adds a recommended product via the power-step-drawer.
  async function handleAddToBasketDrawer(addedRecommendedProduct: Omit<AddedRecommendedProduct, 'success'>) {
    const response = await fetch(`/api/get-product?productId=${addedRecommendedProduct.id}`);
    const { data } = await response.json();

    productRef.current = {
      ...data,
      onlineMinimumQuantity: orderType === OrderType.ClickCollect ? 1 : data.onlineMinimumQuantity || 1,
    };
    const drawerProps =
      orderType === OrderType.OnlineSales ? await handleOnlineSale(true) : await handleClickAndCollect(true);

    if (!drawerProps) return;

    handleOpenStepDrawer({ viaPowerStep: true, orderType, counter: addedRecommendedProduct.counter, ...drawerProps });
  }

  async function handleOpenStepDrawer({ viaPowerStep, response, orderType, carriers, counter }: HandleAddToBasketType) {
    if (response.success) {
      const order = response.shoppingSession.active?.orders?.find((order) => order.orderType === orderType);
      const line = order?.deliveryGroups?.[0].orderLines?.find(
        (orderLine) => orderLine.article === productRef.current.id,
      );

      if (line && order) {
        // TODO: align quantity in tracking (question here: should quantity always be 1, the selected or the total quantity of that product?)
        trackAddToBasket({
          orderLine: getProductAsOrderLine(productRef.current).orderLine,
          basket: order,
          quantity: getAddToBasketQuantity(viaPowerStep),
        });
        orderType === OrderType.ClickCollect &&
          trackCheckStock({
            orderLine: line,
            basket: order,
            quantity: getAddToBasketQuantity(viaPowerStep),
          });
      }
      setLoadingButton(false);

      prevAddedProductRef.current = {
        quantity: getAddToBasketQuantity(viaPowerStep),
        totalInBasket: order ? countOrderLinesInOrder(order) : 0,
        orderType,
        totalPriceAmount: order?.totalPriceAmount || 0,
        totalPriceText: order?.totalPriceText,
        line,
        imageSrc: productRef.current.media?.images?.[0]?.url,
        carriers,
        // keep the initially relations: even if a recommended product is added by the power-step-drawer, it should still list the initially relations (recommended products)
        relations: relations?.complementary,
        onAddToBasket: handleAddToBasketDrawer,
        addedRecommendedProduct:
          counter && productId !== productRef.current.id
            ? {
                id: productRef.current.id,
                counter,
                success: true,
              }
            : undefined,
      };

      NiceModal.show(DRAWER_ID.POWER_STEP_DRAWER, prevAddedProductRef.current);
    } else {
      setLoadingButton(false);
      errorToast({
        message: t('pdp.errors.power-step-failed-to-add'),
      });
      // For the recommendedProducts only: To stop showing loading button in the product-card, show power-step-drawer with previous product data and update only the addedRecommendedProduct prop.
      viaPowerStep &&
        NiceModal.show(DRAWER_ID.POWER_STEP_DRAWER, {
          ...prevAddedProductRef.current,
          addedRecommendedProduct: {
            ...prevAddedProductRef.current?.addedRecommendedProduct,
            id: productRef.current.id,
            success: false,
          },
        });
    }
  }

  const handleDeliveryOption = useCallback(
    (type: OrderType, status: StatusIndicatorStatus) => {
      if (
        (type === OrderType.OnlineSales && !showOnlineStock) ||
        (type === OrderType.ClickCollect && !product.isClickCollect)
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(status !== StatusIndicatorStatus.Success);
      }
      setOrderType(type);
      selectedQuantityRef.current = type === OrderType.OnlineSales ? onlineMinimumQuantity || 1 : 1;
    },
    [setOrderType, setIsDisabled, showOnlineStock],
  );

  async function handleSubmit() {
    setLoadingButton(true);
    productRef.current = {
      ...product,
      onlineMinimumQuantity: onlineMinimumQuantity || 1,
    };
    const drawerProps =
      orderType === OrderType.OnlineSales ? await handleOnlineSale(false) : await handleClickAndCollect(false);

    if (!drawerProps || !drawerProps.response.success) return;

    handleOpenStepDrawer({ ...drawerProps, orderType, viaPowerStep: false });
  }

  return (
    <>
      <ProductAtpOptionsList
        selected={orderType}
        onChange={handleDeliveryOption}
        stockAtp={stockAtp?.[product.id]}
        isLoading={isStockLoading}
        product={product}
        onInit={(status) => setIsDisabled(status !== StatusIndicatorStatus.Success)}
        className="grid-col-2 grid gap-4"
      >
        <div className="flex gap-2.5">
          <ProductQuantityInput
            stepper
            min={1}
            quantity={selectedQuantityRef.current}
            step={1}
            textClassName="text-lg"
            onChange={(value) => (selectedQuantityRef.current = value)}
            deliveryType={orderType}
            onlineMinimumQuantity={onlineMinimumQuantity ?? 1}
          />
          <SubmitButton
            data-testid="add-to-basket-button"
            variant="primary"
            className="w-full"
            onClick={handleSubmit}
            disabled={isDisabled}
            loading={loadingButton}
            loadingMessage={t('common.loading')}
          >
            {t('common.add-to-basket')}
          </SubmitButton>
        </div>
      </ProductAtpOptionsList>
      {orderType === OrderType.OnlineSales &&
        onlineMinimumQuantity &&
        onlineMinimumQuantity > 1 &&
        onlineMinimumPrice &&
        onlineMinimumPrice > 0 && (
          <div className="text-sm">
            {t('pdp.online-minimum-quantity-label', {
              onlineMinimumQuantity,
              onlineMinimumPrice,
              currency,
            })}
          </div>
        )}
    </>
  );
}
