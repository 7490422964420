'use client';

import NiceModal from '@ebay/nice-modal-react';
import { DRAWER_ID } from '@wla/lib/modals';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

type PromptReviewDrawerProps = {
  productId: string;
};

export function PromptReviewDrawer({ productId }: PromptReviewDrawerProps) {
  const searchParams = useSearchParams();

  useEffect(() => {
    const writeReview = searchParams?.get('writeReview');
    if (writeReview === 'true') {
      NiceModal.show(DRAWER_ID.CREATE_REVIEW_DRAWER, { productId });
    }
  }, [searchParams]);

  return null;
}
