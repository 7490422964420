'use client';
import { BlogPostTeaser } from '@jysk/api-types/drupalApi';
import { Carousel, CarouselItem } from '@wla/components/ui/carousel/carousel';
import { Link } from '@wla/components/ui/link';
import Image from 'next/image';

type blogPostTeasersProps = {
  blogPostTeasers: BlogPostTeaser[];
};

export function BlogPostCategorySlider(blogs: blogPostTeasersProps) {
  return (
    <Carousel>
      {blogs.blogPostTeasers?.map((blog) => (
        <CarouselItem key={blog.title}>
          <Link href={blog.url} className="no-underline">
            {blog.imageTeaser && (
              <Image
                className="aspect-[254/169] object-fill md:aspect-[208/138]"
                alt={blog.imageTeaser.alt ?? blog.imageTeaser.title}
                src={blog.imageTeaser.url}
                height={blog.imageTeaser.dimensions?.height}
                width={blog.imageTeaser.dimensions?.width}
              />
            )}
            <h3 className="mt-2 hyphens-auto text-lg font-semibold text-black">{blog.title}</h3>
          </Link>
        </CarouselItem>
      ))}
    </Carousel>
  );
}
