'use client';

import NiceModal from '@ebay/nice-modal-react';
import { ProductPriceRecyclingTaxOneOfRecyclingTaxFee } from '@jysk/api-types/drupalApi';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';

type RecyclingFeeButtonProps = {
  recyclingFee: ProductPriceRecyclingTaxOneOfRecyclingTaxFee | undefined;
};

export function RecyclingFeeButton({ recyclingFee }: RecyclingFeeButtonProps) {
  const t = useTranslations();

  if (!recyclingFee || recyclingFee <= 0) {
    return null;
  }

  return (
    <button
      className="py-1 text-sm text-gray-500 hover:underline"
      onClick={() =>
        NiceModal.show(DRAWER_ID.PDP_SIDE_DRAWER_ID, {
          title: t('pdp.including-recycling-fee'),
          bodySlot: <div className="flex items-center justify-center">TODO</div>,
        })
      }
    >
      {t('pdp.including-recycling-fee', {
        recyclingFee: recyclingFee,
      })}
    </button>
  );
}
