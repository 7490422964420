'use client';

import { usePublicConfig } from '@wla/lib/configs';
import { PageType, getDynamicYieldPageType, getPageType } from '@wla/lib/helpers/(analytics)/get-page-type';
import { CookieTypesEnum, useCookieConsent } from '@wla/lib/hooks/use-cookie-consent';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import Script from 'next/script';
import { useEffect } from 'react';

type DynamicYieldProps = {
  pageType: string;
  productId?: string;
};

export function DynamicYield({ pageType, productId }: DynamicYieldProps) {
  const { orders } = useShoppingSession();
  const { dynamicYield, defaultLocale } = usePublicConfig();
  const orderLines = orders?.flatMap((order) => order.deliveryGroups?.flatMap((group) => group.orderLines));
  const arrayOfArticleIds = orderLines?.map((orderLine) => orderLine?.article);
  const hasConsent = useCookieConsent(CookieTypesEnum.MARKETING);

  useEffect(() => {
    window.DY = window.DY || {};
    window.DY.userActiveConsent = { accepted: hasConsent };
    window.DYO?.ActiveConsent?.updateConsentAcceptedStatus?.(hasConsent);
    try {
      localStorage.setItem('userActiveConsent', `${hasConsent}`);
    } catch (e) {
      console.error("We couldn't save the value of the consent");
    }
    window.DY.recommendationContext = {
      type: getDynamicYieldPageType(pageType),
      lng: defaultLocale.replace('-', '_'),
      ...(pageType !== PageType.HOME && {
        data: (pageType === PageType.CHECKOUT && arrayOfArticleIds) || [productId ?? getPageType(pageType)],
      }),
    };
  }, [hasConsent, pageType, defaultLocale]);

  return (
    <>
      <link rel="preconnect" href="//cdn-eu.dynamicyield.com" />
      <link rel="preconnect" href="//st-eu.dynamicyield.com" />
      <link rel="preconnect" href="//rcom-eu.dynamicyield.com" />
      <link rel="dns-prefetch" href="//cdn-eu.dynamicyield.com" />
      <link rel="dns-prefetch" href="//st-eu.dynamicyield.com" />
      <link rel="dns-prefetch" href="//rcom-eu.dynamicyield.com" />
      {dynamicYield.isEnabled && (
        <>
          <Script
            id="dynamicyield-dynamic"
            src={`//cdn-eu.dynamicyield.com/api/${dynamicYield.id}/api_dynamic.js`}
            strategy="beforeInteractive"
          />
          <Script
            id="dynamicyield-static"
            src={`//cdn-eu.dynamicyield.com/api/${dynamicYield.id}/api_static.js`}
            strategy="beforeInteractive"
          />
        </>
      )}
    </>
  );
}
