'use client';
import { ProductFull } from '@jysk/api-types/drupalApi';
import { trackProductViewed } from '@wla/lib/tracking/google-analytics-tracking';
import { useEffect } from 'react';

type ProductAnalyticsProps = {
  product: ProductFull;
  currency: string;
};

export function ProductAnalytics({ product, currency }: ProductAnalyticsProps) {
  useEffect(() => {
    trackProductViewed({ product, currency });
  }, []);
  return null;
}
