'use client';

import { FilterSearchDrawer } from '@wla/app/(cms)/[...slug]/(filters)/filter-search-drawer';
import { Alert } from '@wla/components/ui/alert';
import { Button } from '@wla/components/ui/button/button';
import { Link } from '@wla/components/ui/link';
import { PaginationProgressbar } from '@wla/components/ui/pagination-progressbar';
import { Spinner } from '@wla/components/ui/spinner';
import { BLOGS_PR_PAGE } from '@wla/lib/constants';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { BlogPostTeaser, BlogPostTypeFull, GetBlogPosts200 } from '@jysk/api-types/drupalApi';
import { PostSearch200ProductsFacets } from '@jysk/api-types/searchApi/model';

type BlogPostSubCategoryProps = {
  id: string;
  posts: BlogPostTeaser[];
  totalElements: number;
  filters?: BlogPostTypeFull[];
};

export function BlogPostSubCategory({ id, filters, posts, totalElements }: BlogPostSubCategoryProps) {
  const t = useTranslations();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams.toString());
  const pathname = usePathname();
  const filterQuery = searchParams.get('filters');
  const pageQuery = searchParams.get('page');
  const filterTypes: PostSearch200ProductsFacets = {
    sorted:
      filters?.map((type) => {
        return {
          name: type.id,
          amount: type.numberOfBlogPosts,
          type: 'boolean',
          isSelected: false,
          numberOfDocuments: type.numberOfBlogPosts,
        };
      }) ?? [],
  };
  const [totalQty, setTotalQty] = useState<number>(totalElements);
  const [toggleFilterDrawer, setToggleFilterDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [blogList, setBlogList] = useState<BlogPostTeaser[]>(posts || []);

  function handleFilterDrawerToggle() {
    setToggleFilterDrawer(!toggleFilterDrawer);
  }

  async function handlePageChange() {
    params.set('page', pageQuery ? (Number(pageQuery) + 1).toString() : '2');
    history.replaceState({}, '', `${pathname}?${params.toString()}`);
  }

  async function getBlogPosts() {
    setIsLoading(true);
    const props = {
      currentPage: '1',
      blogCategoryId: id,
      pageSize: pageQuery ? Number(pageQuery) * BLOGS_PR_PAGE : BLOGS_PR_PAGE,
      types: filterQuery ?? '',
    };

    const response = await fetch('/api/get-blog-posts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ props }),
    });

    if (response.status !== 200) {
      setIsLoading(false);
      return <Alert type="error">{t('inspiration.failed-to-load-blog-post-categories')}</Alert>;
    }
    const res = await response.json();

    const { data, page }: GetBlogPosts200 = res.data;

    if (!data) return;

    setBlogList(data);
    setTotalQty(page?.totalElements || 0);
    setIsLoading(false);
  }

  useEffect(() => {
    getBlogPosts();
  }, [filterQuery, pageQuery]);

  if (!blogList.length) return;

  return (
    <>
      {filters && (
        <>
          <Button
            customIcon="w3-filter"
            iconPosition="left"
            variant="secondary"
            onClick={handleFilterDrawerToggle}
            className="mb-4"
          >
            {t('common.filter')}
          </Button>
          <FilterSearchDrawer
            toggleFilterDrawer={toggleFilterDrawer}
            onFilterDrawerToggle={handleFilterDrawerToggle}
            filters={filterTypes}
            productCount={totalQty}
            isLoading={isLoading}
            showCount
          />
        </>
      )}
      <div className="flex flex-col flex-wrap gap-8 md:flex-row">
        {blogList.map((blog) => (
          <div key={blog.title} className="w-full md:w-[calc(50%-16px)]">
            <Link href={blog.url} className="no-underline">
              {blog.imageTeaser && (
                <Image
                  className="aspect-[3/2] object-cover"
                  alt={blog.imageTeaser.alt ?? blog.imageTeaser.title}
                  src={blog.imageTeaser.url}
                  height={blog.imageTeaser.dimensions?.height}
                  width={blog.imageTeaser.dimensions?.width}
                />
              )}
              <div className="mt-4 hyphens-auto text-2xl font-semibold text-black">{blog.title}</div>
              <div className="mb-4 hyphens-auto text-base text-black">{blog.summary}</div>
            </Link>
            <div className="flex flex-col items-start">
              <Button as="a" variant="secondary" className="lg:w-fit" href={blog.url}>
                {t('common.read-more')}
              </Button>
            </div>
          </div>
        ))}
      </div>
      {isLoading && (
        <div className="mb-8 mt-16 flex items-center justify-center">
          <Spinner size="md" />
        </div>
      )}
      <PaginationProgressbar
        className="flex w-full items-center justify-center"
        currentPage={Number(pageQuery) || 1}
        onPageChange={handlePageChange}
        totalElements={totalQty}
        pageSize={BLOGS_PR_PAGE}
        isLoading={isLoading}
      />
    </>
  );
}
