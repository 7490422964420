import { cn } from '@wla/lib/helpers/cn';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { RoleType } from './product-quantity-input';

type ProductQuantityTextProps = {
  value: number;
  step: number;
  role: RoleType.DIRECT;
  action: (role: RoleType, v: number) => void;
  min: number;
  className?: string;
  disabled?: boolean;
  size?: 'sm' | 'lg';
};

export function ProductQuantityText({
  value: valueProp,
  role,
  step,
  action,
  min,
  disabled = false,
  className = '',
  size = 'lg',
}: ProductQuantityTextProps) {
  const [localValue, setLocalValue] = useState<number | undefined>(Math.max(min, valueProp));
  const ref = useRef<HTMLInputElement>(null);

  const numericLocalValue = useMemo(() => Math.max(min, localValue ? localValue : min), [localValue, min]);

  const setNewValue = useCallback(
    (v: number) => {
      const newLocalValue = Math.max(min, v);
      setLocalValue(Number.isNaN(v) ? undefined : newLocalValue);
    },
    [min],
  );

  const handleBlur = useCallback(
    (_e: React.FormEvent<HTMLInputElement>) => {
      action(role, numericLocalValue);
    },
    [action, numericLocalValue, role],
  );

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      let newValue = e.currentTarget.value;
      if (newValue.length > 5) {
        newValue = newValue.slice(0, 5);
      }
      const newLocalValue = Number.parseInt(newValue, 10);
      setNewValue(newLocalValue);
    },
    [setNewValue],
  );
  const getDisplayValue = (v: number | undefined) => (v ? v.toString() : '');

  const handleKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          setNewValue(numericLocalValue + step);
          break;

        case 'ArrowDown':
          e.preventDefault();
          setNewValue(numericLocalValue - step);
          break;

        case 'Enter':
        case 'Tab':
          e.preventDefault();
          setNewValue(numericLocalValue);
          ref.current?.blur(); // will trigger handleBlur above
          break;

        default:
          break;
      }
    },
    [setNewValue, numericLocalValue, step],
  );

  // obey changes to quantity from outside
  useEffect(() => {
    setLocalValue(valueProp);
  }, [valueProp]);

  const getProps = () => {
    return {
      'aria-valuemin': min,
      'aria-valuenow': localValue,
      'aria-valuetext': getDisplayValue(localValue),
      'aria-label': 'Quantity',
      autoComplete: 'off',
      autoCorrect: 'off',
      spellCheck: false,
      type: 'text',
      disabled: disabled,
      // grow width, to make room for number-of-characters + 5
      style: { width: getDisplayValue(localValue).length + 5 + 'ch' },
    };
  };

  return (
    <input
      {...getProps()}
      ref={ref}
      name="quantity"
      value={getDisplayValue(localValue)}
      className={cn('remove-arrow border-none text-center focus:ring-0', className)}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeydown}
      pattern="\d*"
      type="number"
    />
  );
}
