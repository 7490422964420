'use client';

import { ProductVariantType } from '@wla/app/(cms)/[...slug]/(product-detail-page)/product-size-picker';
import { Link } from '@wla/components/ui/link';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { ProductFullAllOfRelationsOneOfFamily, ProductFullAllOfVariants } from '@jysk/api-types/drupalApi';

type ProductColourPickerProps = {
  variants: ProductFullAllOfVariants;
  productRelation?: ProductFullAllOfRelationsOneOfFamily;
  productId?: string;
};

export function ProductColourPicker({ variants, productId, productRelation }: ProductColourPickerProps) {
  const t = useTranslations('common');

  if (!variants) {
    return null;
  }

  const currentProduct = variants.products.find(
    (product) => product.id === (productId || productRelation?.defaultProductId),
  );

  const currentProductSize = currentProduct?.options.find((option) =>
    option.typeId.toLowerCase().includes(ProductVariantType.Size.toLowerCase()),
  )?.name;

  // Filter to only include products with the same size option as the current product and with a color option
  const filteredProducts = variants.products.filter((product) => {
    const hasSameSize = product.options.some(
      (option) =>
        option.typeId.toLowerCase().includes(ProductVariantType.Size.toLowerCase()) &&
        option.name === currentProductSize,
    );
    const hasColourOption = product.options.some((option) =>
      option.typeId.toLowerCase().includes(ProductVariantType.Colour.toLowerCase()),
    );
    return hasSameSize || hasColourOption;
  });

  // Get unique products by color option
  const uniqueProductsByColor = Object.values(
    filteredProducts.reduce(
      (acc, product) => {
        const colorOption = product.options.find((option) =>
          option.typeId.toLowerCase().includes(ProductVariantType.Colour.toLowerCase()),
        )?.name;
        if (colorOption && !acc[colorOption]) {
          acc[colorOption] = product;
        }
        return acc;
      },
      {} as Record<string, (typeof filteredProducts)[0]>,
    ),
  );

  // Only render the colour picker if there are multiple unique color options
  if (uniqueProductsByColor.length <= 1) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2.5">
      <label htmlFor="colour">
        {t.rich('colour', {
          colour: currentProduct?.options.find((option) =>
            option.typeId.toLowerCase().includes(ProductVariantType.Colour.toLowerCase()),
          )?.name,
        })}
      </label>
      <div className="grid grid-cols-5 gap-2 sm:grid-cols-7 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7">
        {uniqueProductsByColor.map(
          (product) =>
            product.imageUrl && (
              <Link
                key={product.id}
                href={`/product-lookup/${product.id}`}
                className={cn('flex min-h-20 min-w-14 items-center justify-center bg-gray-100', {
                  'border-b-2 border-black': product.id === currentProduct?.id,
                })}
              >
                <Image
                  src={product.imageUrl}
                  width={55}
                  height={75}
                  alt={product.options.find((option) => option.typeId === ProductVariantType.Colour)?.name || ''}
                  className="w-10 cursor-pointer bg-gray-100 object-contain mix-blend-darken"
                />
              </Link>
            ),
        )}
      </div>
    </div>
  );
}
