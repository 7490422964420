'use client';

import { Transition } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from '@wla/components/ui/alert';
import { Button } from '@wla/components/ui/button/button';
import { EmailInput } from '@wla/components/ui/forms/email-input';
import { FileUpload } from '@wla/components/ui/forms/file-upload';
import { Form } from '@wla/components/ui/forms/form';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { Select } from '@wla/components/ui/forms/select';
import { SubmitButton } from '@wla/components/ui/forms/submit-button';
import { TextArea } from '@wla/components/ui/forms/textArea';
import { usePublicConfig } from '@wla/lib/configs';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { ChangeEvent, useState } from 'react';
import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';
import { StoreFull } from '@jysk/api-types/drupalApi';
import { SalesForceEmailData, salesForceEmailSchema } from './sales-force-form-schema';

type SalesForceFormProps = {
  email: string | null;
  fileUpload?: boolean;
  showOrderNumber?: boolean;
  storesList?: StoreFull[];
  subject?: string;
  className?: boolean;
};

const initialState = {
  success: false,
  message: '',
};

export function SalesForceForm({
  email,
  fileUpload,
  showOrderNumber = false,
  storesList,
  subject,
  className,
}: SalesForceFormProps) {
  const t = useTranslations();
  const { defaultLocale } = usePublicConfig();
  const language = defaultLocale.substring(3, 5);
  const [toggleForm, setToggleForm] = useState(false);
  const [messageLength, setMessageLength] = useState(0);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [files, setFiles] = useState<FileList | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [result, formAction] = useFormState(async (prevState: any, formData: FormData) => {
    const data = {
      toAddress: email || `cs_${language}_order@jysk.com`,
      email: formData.get('email'),
      firstName: formData.get('firstName'),
      lastName: formData.get('lastName'),
      phone: formData.get('phoneNumber'),
      message: formData.get('message'),
      subject,
      orderNumber: formData.get('orderNumber') || '',
      store: formData.get('store'),
    };
    const result = salesForceEmailSchema.safeParse(data);

    if (result.success) {
      try {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));

        if (files) {
          Array.from(files).forEach((file) => {
            formData.append('files', file);
          });
        }

        const response = await fetch('/api/post-sales-force-form', {
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          return { success: true, message: t('customer-service.email-success') };
        }
      } catch (error) {
        return { success: false, message: t('forms.errors.something-went-wrong') };
      }
    }
    return { success: false, message: t('forms.errors.something-went-wrong') };
  }, initialState);

  function handleFormToggle() {
    setToggleForm(!toggleForm);
  }

  function handleOnFilesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFiles = event.target.files;
    if (!selectedFiles) return;
    setFiles(selectedFiles);
    const previews = Array.from(selectedFiles).map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<string>((resolve) => {
        reader.onload = () => resolve(reader.result as string);
      });
    });
    Promise.all(previews).then(setImagePreviews);
  }

  const form = useForm<SalesForceEmailData>({
    mode: 'all',
    resolver: zodResolver(salesForceEmailSchema),
  });
  const {
    register,
    formState: { errors },
  } = form;

  function handleOnMessageChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setMessageLength(event.target.value.length);
  }

  return (
    <>
      <Button type="button" variant="secondary" className="mt-2" onClick={handleFormToggle}>
        {t('forms.email')}
      </Button>
      <Transition
        enter="transition-[grid-template-rows] duration-500 ease-in-out"
        enterFrom="grid-rows-[0fr]"
        enterTo="grid-rows-[1fr]"
        leave="transition-[grid-template-rows] duration-500 ease-in-out"
        leaveFrom="grid-rows-[1fr]"
        leaveTo="grid-rows-[0fr]"
        className="grid"
        as="div"
        show={toggleForm}
      >
        <div className="overflow-hidden">
          <Form form={form} action={formAction} className={cn('mt-4 flex h-auto flex-col gap-3', className)}>
            <FormGroup
              label={t('forms.email')}
              inputType={InputTypes.Email}
              required
              validationError={errors.email?.message ? t('forms.errors.not-valid.email-not-valid') : ''}
              className="flex-1"
            >
              <EmailInput {...register('email')} />
            </FormGroup>
            <FormGroup
              label={t('forms.first-name')}
              required
              inputType={InputTypes.Input}
              validationError={errors.firstName?.message ? t('forms.errors.required.first-name-is-required') : ''}
              className="flex-1"
            >
              <Input {...register('firstName')} />
            </FormGroup>
            <FormGroup
              label={t('forms.last-name')}
              required
              inputType={InputTypes.Input}
              validationError={errors.lastName?.message ? t('forms.errors.required.last-name-is-required') : ''}
              className="flex-1"
            >
              <Input {...register('lastName')} />
            </FormGroup>
            <FormGroup
              label={t('forms.mobile-number')}
              required
              inputType={InputTypes.Input}
              validationError={errors.phoneNumber?.message ? t('forms.errors.required.phone-number-is-required') : ''}
              className="flex-1"
            >
              <Input {...register('phoneNumber')} />
            </FormGroup>
            {showOrderNumber && (
              <FormGroup
                label={t('forms.order-number')}
                inputType={InputTypes.Input}
                validationError={
                  errors.orderNumber?.message ? t('forms.errors.not-valid.order-number-must-be-numbers') : ''
                }
                className="flex-1"
              >
                <Input {...register('orderNumber')} type="text" inputMode="numeric" />
              </FormGroup>
            )}
            {Boolean(storesList?.length) && (
              <FormGroup inputType={InputTypes.Select} className="flex-1">
                <Select {...register('store')} arrowDirection="down" defaultValue={t('forms.stores')}>
                  <option>{t('forms.stores')}</option>
                  {storesList?.map((store) => (
                    <option key={store.id} value={store.id}>
                      {store.name}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            )}
            <FormGroup
              label={t('forms.message')}
              inputType={InputTypes.TextArea}
              required
              validationError={errors.message?.message ? t('forms.errors.required.message-is-required') : ''}
            >
              <TextArea
                {...register('message')}
                counter={messageLength}
                onChange={handleOnMessageChange}
                maxLength={300}
                className="h-40"
                resize="none"
              />
            </FormGroup>
            {fileUpload && (
              <>
                <FileUpload
                  accept="image/png,image/jpeg,image/gif,application/pdf,.doc,.docx,.heic"
                  onChange={handleOnFilesChange}
                  multiple
                />
                {imagePreviews.length > 0 && (
                  <div className="flex gap-2">
                    {imagePreviews.map((src, index) => (
                      <Image key={src} src={src} alt={`Uploaded image ${index + 1}`} width="70" height="30" />
                    ))}
                  </div>
                )}
                <div
                  className="text-sm text-gray-500"
                  dangerouslySetInnerHTML={{ __html: t.raw('forms.upload-text') }}
                />
              </>
            )}
            <SubmitButton translate="no" variant="primary">
              {t('customer-service.send-email-submit')}
            </SubmitButton>
          </Form>
        </div>
      </Transition>
      {result.message && (
        <Alert className="mt-4" type={result.success ? 'success' : 'warning'}>
          {result.message}
        </Alert>
      )}
    </>
  );
}
