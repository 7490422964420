'use client';

import NiceModal from '@ebay/nice-modal-react';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

type ProductDataSheetButtonProps = {
  energyLabel: string;
};

export function ProductDataSheetButton({ energyLabel }: ProductDataSheetButtonProps) {
  const t = useTranslations();

  return (
    <button
      className="py-1 pl-4 text-sm text-gray-500 hover:underline"
      onClick={() =>
        NiceModal.show(DRAWER_ID.PDP_SIDE_DRAWER_ID, {
          title: t('pdp.plus-possibly-freight'),
          bodySlot: (
            <div className="flex items-center justify-center">
              <Image src={energyLabel} alt="energyMarking" width={350} height={700} />
              {/* TODO */}
            </div>
          ),
        })
      }
    >
      {t('pdp.product-data-sheet')}
    </button>
  );
}
