import NiceModal from '@ebay/nice-modal-react';
import { AdditionalMediaProps } from '@wla/app/(cms)/[...slug]/(product-detail-page)/helpers/product-images-mapping';
import { Button } from '@wla/components/ui/button/button';
import { cn } from '@wla/lib/helpers/cn';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { ProductImageFull } from '@jysk/api-types/drupalApi';

export type FullImageType = ProductImageFull & AdditionalMediaProps;

type ProductImageType = {
  image: FullImageType;
  image360?: FullImageType;
  index: number;
  totalMediasQuantity: number;
  onProductImageClick: (index: number) => void;
};

export function ProductImage({ image, image360, index, totalMediasQuantity, onProductImageClick }: ProductImageType) {
  const t = useTranslations('pdp');

  return (
    <>
      <Image
        priority
        src={image.url}
        alt={image.alt}
        sizes="(max-width: 359px) 300px, (max-width: 430px) 436px, (max-width: 768px) 767px, (max-width: 992px) 300px, (max-width: 1200px) 436px, 436px"
        width={image.dimensions?.width || 436}
        height={image.dimensions?.height || 436}
        className={cn('relative h-full object-contain', { 'w-full': totalMediasQuantity === 1 })}
        onClick={() => onProductImageClick(index)}
      />
      {image360 && index === 0 && (
        <Button
          variant="secondary"
          customIcon="w3-360-marker"
          iconPosition="left"
          size="medium"
          className="absolute bottom-5 left-5 h-11 border-none bg-white"
          onClick={() => NiceModal.show(DRAWER_ID.PRODUCT_GALLERY_DRAWER, { medias: [image360], is360Image: true })}
        >
          {t('360-view-button')}
        </Button>
      )}
    </>
  );
}
